/** Cведения о приемке */
export const AcceptanceStatuses = {
    Undefined: 'Undefined',                       // Неизвестный статус
    Draft: 'Draft',                               // Черновик
    Appointmented: 'Appointmented',               // Приглашен
    Participant: 'Participant',                   // Участники
    CheckPersonalData: 'CheckPersonalData',       // Проверка персональных данных
    ChooseDate: 'ChooseDate',                     // Выбор даты
    NeedPrepareDocuments: 'NeedPrepareDocuments', // Ожидание встречи
    Meeting: 'Meeting',                           // Встреча на объекте
    SuccessResult: 'SuccessResult',               // Результат без замечаний
    ResultWithRemarks: 'ResultWithRemarks',       // С замечаниями
    FailResult: 'FailResult',                     // Результат без подписи о приемке
    Cancelled: 'Cancelled',                       // Отменена
} as const;

/** Cведения о приемке */
export const AcceptanceUIStatuses = {
    Undefined: 'Undefined',                       // Неизвестный статус
    New: 'New',                                   // Новая
    Invited: 'Invited',                           // Приглашен
    Appointmented: 'Appointmented',               // Записан
    Prepared: 'Prepared',                         // Подготовлен                          
    SuccessResult: 'SuccessResult',               // Завершена без замечаний
    ResultWithRemarks: 'ResultWithRemarks',       // Завершена с замечаниями
    FailResult: 'FailResult',                     // Завершена без АПП
    Cancelled: 'Cancelled',                       // Отменена
} as const;

